<template>
  <v-col cols="12" md="7" :lg="setColAspect()" id="search_prd" class="pa-0 ma-0 grey lighten-4"
    :class="is_mobile ? 'cart_mobile' : ''">
    <v-row class="pa-0 ma-0 pr-lg-0 pr-2 grey lighten-4">
      <v-col cols="12">
        <ng-text-keyboard :filled="false" :dense="setDense()" :placeholder="$t('placeholder_search_products')"
          append-icon="mdi-magnify" autofocus v-model="query" @click:clear="clear()" @click:append="enterEvent"
          @keyup.enter="enterEvent" hide-details :nudgetTop="0" :readOnly="false" inputmode="none"
          background-color="grey lighten-5"></ng-text-keyboard>
      </v-col>
      <!-- @input="search" -->
      <!-- <v-col cols="12" sm="8" md="8" lg="8">
        <ng-text-keyboard :filled="false" :dense="setDense()" :placeholder="$t('Código de barras')"
          append-icon="mdi-magnify" autofocus v-model="query" @keyup.enter="searchByCodeBar" hide-details :nudgetTop="0"
          :readOnly="false" inputmode='none' background-color="white"></ng-text-keyboard>
      </v-col> -->

      <v-col v-if="$store.getters.mobile" cols="12" :class="is_mobile ? 'pt-0' : ''">
        <CategoryFilter @change="filterByCategory" :is_mobile="is_mobile" />
      </v-col>
    </v-row>

    <v-divider />

    <v-row no-gutters class="px-5" v-if="!$store.getters.mobile">

      <v-col id="btn_show_cat" cols="12" class="d-flex flex-row flex-end mt-3" v-if="showButtonCategories()">
        <v-spacer />
        <v-btn small rounded @click="toggleCategories" color="primary">
          <v-icon small left>{{ showCategories ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }}</v-icon>{{ showCategories
            ?
            'Ocultar Categorías' : 'Mostrar Categorías' }}
        </v-btn>
      </v-col>

      <v-col id="breadcrumbs" cols="12" class="py-0 py-3" v-if="breadcrumbs.length > 0">
        <v-breadcrumbs :items="['INICIO', ...breadcrumbs]" class="pa-0" large
          v-if="breadcrumbs.length > 0">
          <template v-slot:item="{ item }">
            <v-btn class="btn_breadcrumb" color="primary" text small rounded @click="handleButtonClick(item)" :disabled="item.disabled">
              {{ item === 'INICIO' ? 'INICIO' : item.text }}
            </v-btn>
          </template>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </v-col>
      
      <v-col id="grid_categories_all" cols="12" :class="!breadcrumbs.length > 0 ? '' : 'mt-3'" v-if="showButtonCategories()">
        <v-expand-transition>
          <v-row v-if="showCategories" class="pa-1 pt-3">
            <v-col cols="6" md="4" lg="3" xl="2" v-for="cat of visibleCategories" :key="cat.id">
              <v-card class="text-center position-relative" @click="navigateByCategory(cat)">
                <v-btn class="ctg_qty primary--text" fab small color="white"
                  v-if="cat.children && cat.children.length > 0">
                  {{ cat.children.length }}
                </v-btn>
                <v-img src="/assets/tiny-galery.png" class="white--text align-end"
                  gradient="to right bottom, rgba(0,0,0,0.07), rgba(0,0,0,0.03)" max-height="100px">
                  <v-card-title class="glass py-1">
                    <h6 class="text-center mx-auto font-weight-regular truncated-text">
                      {{ cat.name }}
                    </h6>
                  </v-card-title>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-col>

      <v-col id="grid_categories_limit" cols="12" :class="!breadcrumbs.length > 0 ? '' : 'mt-3'" v-else>
        <v-expand-transition>
          <v-row  class="pa-1 pt-3">
            <v-col cols="6" md="4" lg="3" xl="2" v-for="cat of visibleCategories.slice(0,limitCategories)" :key="cat.id">
              <v-card class="text-center position-relative" @click="navigateByCategory(cat)">
                <v-btn class="ctg_qty primary--text" fab small color="white"
                  v-if="cat.children && cat.children.length > 0">
                  {{ cat.children.length }}
                </v-btn>
                <v-img src="/assets/tiny-galery.png" class="white--text align-end"
                  gradient="to right bottom, rgba(0,0,0,0.07), rgba(0,0,0,0.03)" max-height="100px">
                  <v-card-title class="glass py-1">
                    <h6 class="text-center mx-auto font-weight-regular truncated-text">
                      {{ cat.name }}
                    </h6>
                  </v-card-title>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-col>



      <v-col cols="12">
        <v-row no-gutters :key="key" v-if="!is_mobile">
          <v-col cols="12" sm="12" md="6" lg="4" xl="3" class="pa-0 ma-0 mt-3" v-for="(product, index) in products"
            :key="index" :class="{ 'product-without-price': !product.price }">
            <ProductCard :item="product" @select:product="emitProductSelected" :priceList="priceList" />
          </v-col>
          <v-col cols="12" class="text-center my-5"><v-btn outlined block color="primary" @click="loadMore"><v-icon
                left>mdi-reload</v-icon> {{ $t("load_more") }}</v-btn></v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row no-gutters class="prd_cart_mobile pa-2" :style="getHeight()" :key="key" v-else>
      <v-col cols="12">
        <v-list three-line>
          <template v-for="(product, index) in products">
            <ProductCardMobile :item="product" @select:product="emitProductSelected" :key="index"
              :priceList="priceList" />
            <v-divider :key="product.id"></v-divider>
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import ProductCard from "./ProductCard";
import ProductCardMobile from "./ProductCardMobile";
import CategoryFilter from "./CategoryFilter";

export default {
  name: "SearchProducts",
  components: { ProductCard, ProductCardMobile, CategoryFilter },
  props: ["is_mobile", "aspect_ratio", "priceList"],
  data: () => ({
    showCategories: false,
    breadcrumbs: [],
    categoryItems: [],
    currentCategory: null,
    openInstall: false,
    installed: false,
    key: 0,
    query: "",
    products: [],
    page: 0,
    limitCategories: 7
    //priceList:
  }),

  async mounted() {
    //await this.$db.load();
    this.updateProducts();

    this.categoryItems = await this.$db.getAllCategories();
  },
  /* beforeUpdate(){
     this.search();
   },*/
  watch: {
    keyReload() {
      this.search();
    },
  },
  computed: {
    visibleCategories() {
      if (this.currentCategory) {
        return this.currentCategory.children;
      } else {
        return this.categoryItems;
      }
    },
  },
  methods: {
    showButtonCategories(){
      if(this.visibleCategories.length > this.limitCategories){
        return true;
      }
      return false;
    },
    toggleCategories() {
      this.showCategories = !this.showCategories; // Cambia el estado de visibilidad
    },
    removeFiltersAndNavigateTo(bc) {
      let index = this.breadcrumbs.findIndex(
        (elemento) => elemento.text === bc.text
      );

      if (index + 1 == this.breadcrumbs.length) {
        return;
      }
      this.breadcrumbs.splice(index + 1);
      let idx = this.categoryItems.findIndex(
        (elemento) => elemento.name === bc.text
      );
      this.currentCategory = this.categoryItems[idx];
      this.filterByCategory([this.currentCategory.id]);
    },
    removeFilterCategory(bc) {
      if(bc == 'INICIO'){
        console.log('es inicio');
        this.currentCategory = null;
        this.breadcrumbs = [];
        this.updateProducts({});
      }else{
        let index = this.breadcrumbs.findIndex(
          (elemento) => elemento.text === bc.text
        );
        if (index === -1) {
          index = 0;
        }
        
        if (index >= 0) {
          let totalDeleteBreadCrumbs = this.breadcrumbs.length - ( index + 1);
          this.breadcrumbs.splice(index+1, totalDeleteBreadCrumbs);
          let before = this.breadcrumbs[index];
          let idx = this.categoryItems.findIndex(
            (elemento) => elemento.name === before.text
          );
          this.currentCategory = this.categoryItems[idx];
          this.filterByCategory([this.currentCategory.id]);
        }

      }
      


      /*
      this.breadcrumbs.splice(index);

      if (index == 0) {
        this.currentCategory = null;
      } else {
        let before = this.breadcrumbs[index - 1];
        let idx = this.categoryItems.findIndex(
          (elemento) => elemento.name === before.text
        );

        this.currentCategory = this.categoryItems[idx];
        this.filterByCategory([this.currentCategory.id]);

      }*/
      //this.filterByCategory([this.currentCategory.id])
    },
    navigateByCategory(cat) {      
      this.breadcrumbs.push({ id: cat.id, text: cat.name, disabled: false, href: "#" });
      this.currentCategory = cat;
      this.setLastBreadCrumbDisabled();
      this.filterByCategory([cat.id]);
    },
    filterByCategory(cats) {
      let params = {
        $or: [{ category_id: { $in: cats } }],
      };
      if (cats.length == 0) {
        params = {};
      }
      this.updateProducts(params);
    },
    emitProductSelected(pr) {
      this.$emit("select:product", pr);
    },
    setLastBreadCrumbDisabled(){
      if (this.breadcrumbs.length > 0) {
        this.breadcrumbs.forEach((item, index, array) => {
          if (index === array.length - 1) {
            item.disabled = true; 
          } else{
            item.disabled = false; 
          }
        });
      }
    },
    enterEvent() {
      this.search();
    },
    clear() {
      this.query = "";
      this.search();
    },
    async search() {
      if (await this.searchByCodeBar()) {
        return;
      }
      const params = {
        $or: [
          { name: { $regex: [this.query, "i"] } },
          //{'category': { '$regex': [this.query, 'i'] }},
          { reference: { $regex: this.query } },
          { bar_code: { $regex: this.query } },
        ],
      };
      this.updateProducts(params);
    },
    async searchByCodeBar() {
      let params = {
        $or: [
          { reference: { $eq: this.query } },
          { bar_code: { $eq: this.query } },
        ],
      };
      if (!this.query) {
        return false;
      }
      let products = this.$db.searchProductByCodeBarOrReference(params, 1);
      if (products && products.length == 1) {
        this.$emit("select:product", products[0]);
        this.query = "";
        return true;
      } else {
        params = {
          $or: [
            { reference: { $eq: this.query } },
            { bar_code: { $eq: this.query } },
          ],
        };
        let productVariants = this.$db.searchProductVariantByCodeBarOrReference(
          params,
          1
        );
        if (productVariants && productVariants.length == 1) {
          this.$emit("select:product-variant", productVariants[0]);
          this.query = "";
          return true;
        }
      }
      return false;
    },
    /*installedEvent(){
     this.installed = true;
     this.updateProducts();
    },*/
    updateProducts(params) {
      this.products = this.$db.searchProducts(params, 20, "name");
    },
    loadMore() {
      if (this.breadcrumbs.length > 0) {
        this.page = 0;
      } else {
        this.page = this.page + 1;
        let offset = this.page * 20;
        let items = this.$db.searchProducts({}, 20, "name", offset);
        if (items && items.length > 0) {
          this.products = [...this.products, ...items];
        }
      }
    },
    handleButtonClick(item) {
      this.removeFilterCategory(item);
      this.loadMore();
    },
    setDense() {
      if (this.is_mobile) {
        return true;
      }
      return false;
    },
    getHeight() {
      let vHeight = 87;
      if (this.is_mobile) {
        return "";
      } else {
        if (this.aspect_ratio) {
          if (this.aspect_ratio.screen_height) {
            if (this.aspect_ratio.screen_height == 768) {
              vHeight -= 5;
            }
            if (this.aspect_ratio.screen_height == 800) {
              vHeight -= 4;
            }
            if (this.aspect_ratio.screen_height == 900) {
              vHeight -= 2;
            }
            if (this.aspect_ratio.screen_height == 1024) {
              vHeight -= 3;
            }
          }
        }
      }
      return "height: " + vHeight + "vh; overflow-y:auto;";
    },
    setColAspect() {
      const aspect = ["5:4", "4:3", "16:9", "16:10"];
      if (this.aspect_ratio) {
        if (this.aspect_ratio.aspect_ratio) {
          if (this.inArray(this.aspect_ratio.aspect_ratio, aspect)) {
            return 7;
          }
        }
      }
      return 8;
    },
    inArray(needle, haystack) {
      var length = haystack.length;
      for (var i = 0; i < length; i++) {
        if (haystack[i] == needle) return true;
      }
      return false;
    },
  },
};
</script>
<style>
#search_prd {
  overflow-y: scroll;
  height: 100vh;
}

.truncated-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-without-price {
  border-radius: 4px;
  background: var(--v-error-lighten2) !important;
}

.ctg_qty {
  position: absolute;
  top: 0;
  right: 0;
  margin: -10px;
  z-index: 1;
  outline: 2px solid #24a9e1;
}

.glass {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(36, 169, 225, 0.75);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}
</style>